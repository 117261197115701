import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Box from "../../../../../components/box"
import Note from "../../../../../components/note"
import InlineHelp from "../../../../../components/inline-help"
import SyllablesTask from "../../../../../components/syllables-task"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "jahreszeiten",
          chapterId: "01-jahreszeitengedicht",
          taskId: "silben",
        })
        navigate("/kurse/jahreszeiten/01-jahreszeitengedicht/silben/loesung")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Silben" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Silben klopfen
            </Heading>
          </Stack>
          <Stack space={6}>
            <Note variant="task">
              <>
                Zähle die Silben der Zeilen. Hölderlin hat seine Silben beim
                Schreiben direkt auf den Tisch geklopft. Das kannst du auch
                machen, indem du die Worte laut vor dich hinsagst und bei jeder
                Silbe den{" "}
                <span
                  role="img"
                  aria-label="Auf den Tisch klopfende Faust nach links"
                >
                  🤛
                </span>
                -Button drückst.
              </>
            </Note>
            <InlineHelp title="Wie erkenne ich eine Silbe?">
              Manche Wörter bestehen nur aus einer Silbe (z.B. Eis oder Tee).
              Manche Wörter bestehen aber auch aus mehreren Silben (z.B. Ku-chen
              oder Mar-me-la-de). Du erkennst sie daran, dass du zwischen den
              Silben beim Sprechen eine Pause machen kannst. Bei den meisten
              Wörtern beginnt eine neue Silbe mit einem Konsonanten.
            </InlineHelp>
          </Stack>
          <Stack>
            <SyllablesTask name="q-01">
              <strong>Der Frühling</strong>
            </SyllablesTask>
            <Stack space={3}>
              <SyllablesTask name="q-02">
                Wenn aus der Tiefe kommt der Frühling in das Leben,
              </SyllablesTask>
              <SyllablesTask name="q-03">
                Es wundert sich der Mensch, und neue Worte streben
              </SyllablesTask>
              <SyllablesTask name="q-04">
                Aus Geistigkeit, die Freude kehret wieder
              </SyllablesTask>
              <SyllablesTask name="q-05">
                Und festlich machen sich Gesang und Lieder.
              </SyllablesTask>
            </Stack>
            <Stack space={3}>
              <SyllablesTask name="q-06">
                Das Leben findet sich aus Harmonie der Zeiten,
              </SyllablesTask>
              <SyllablesTask name="q-07">
                Daß immerdar den Sinn Natur und Geist geleiten,
              </SyllablesTask>
              <SyllablesTask name="q-08">
                Und die Vollkommenheit ist Eines in dem Geiste,
              </SyllablesTask>
              <SyllablesTask name="q-09">
                So findet vieles sich, und aus Natur das Meiste.
              </SyllablesTask>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
